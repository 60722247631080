export function rangedValues(start: number, end: number) {
  if (start >= end) {
    return [];
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

export function partitionize<T>(array: T[], size: number): T[][] {
  if (size <= 0) {
    throw new Error(`partitionize: expected (strictly) positive size, got: ${size}`);
  }

  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) => array.slice(i * size, i * size + size));
}
